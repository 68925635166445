import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PortfolioContainer = styled.section`
  padding: 4rem 5%;
  background-color: #ffffff;
`;

const PortfolioContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  justify-items: center;
`;

const Card = styled(motion.div)`
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  h3 {
    padding: 1rem;
    text-align: center;
    font-size: 1.2rem;
    color: #333;
  }
`;

function Portfolio() {
  const projects = [
    { title: "Проект 1", image: "/images/1.jpg" },
    { title: "Проект 2", image: "/images/2.jpg" },
    { title: "Проект 3", image: "/images/3.jpg" },
    { title: "Проект 4", image: "/images/4.jpg" },
  ];

  return (
    <PortfolioContainer id="portfolio">
      <PortfolioContent>
        <Title>Примеры наших работ</Title>
        <PortfolioGrid>
          {projects.map((project, index) => (
            <Card
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <img src={project.image} alt={project.title} />
              <h3>{project.title}</h3>
            </Card>
          ))}
        </PortfolioGrid>
      </PortfolioContent>
    </PortfolioContainer>
  );
}

export default Portfolio;