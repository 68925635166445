import React from 'react';
import styled from 'styled-components';

const MissionContainer = styled.section`
  padding: 4rem 5%;
  background-color: #f8f8f8;
  text-align: center;
`;

const MissionContent = styled.div`
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: #1a1a1a;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

function Mission() {
    return (
      <MissionContainer id="mission">
        <MissionContent>
          <h2>Миссия компании</h2>
          <p>Мы делаем жизнь современной, комфортной, уютной. Мы делаем людей счастливыми. Счастливые люди - Великая страна!</p>
        </MissionContent>
      </MissionContainer>
    );
  }
  
  export default Mission;