import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const PricesContainer = styled.section`
  padding: 4rem 5%;
  background-color: #f8f8f8;
`;

const PricesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const PriceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled(motion.div)`
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }

  h3 {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 0.5rem;
  }

  p {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const Button = styled(motion.a)`
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #ffd700;
  color: #1a1a1a;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 1rem;

  &:hover {
    background-color: #ffea00;
  }
`;

function Prices() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const prices = [
    { name: "Изготовление и монтаж металлоконструкций", description: "Изготовим по вашему проекту", price: "от 20000 руб" },
    { name: "Обслуживание систем вентиляции", description: "Обслуживание организаций", price: "от 20000 руб" },
    { name: "Разработка проектов и сметной документации", description: "Разработаем чертежи и сметы", price: "от 20000 руб" },
    { name: "Строительство под ключ", description: "Разработаем проект, составим сметы, произведем монтаж", price: "от 50000 руб" },
    { name: "Реконструкция зданий", description: "Замена электропроводки, ремонт здания, замена трубопровода и отопления", price: "от 50000 руб" },
    { name: "Кровельные работы", description: "Диагностика пвх мембраны, устройство огнезащитного покрытия, демонтаж и монтаж покрытия", price: "от 15000 руб" },
    { name: "Обслуживание кондиционеров", description: "Обслуживание и ремонт кондиционеров", price: "15000 руб" },
    { name: "Ремонт и замена зенитных фонарей", description: "Конструкции любой сложности", price: "от 20000 руб" }
  ];

  return (
    <PricesContainer id="prices" ref={ref}>
      <PricesContent>
        <Title>Цены на товары и услуги</Title>
        <PriceGrid>
          {prices.map((item, index) => (
            <Card
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <h3>{item.name}</h3>
              <p>{item.description}</p>
              <p><strong>Цена:</strong> {item.price}</p>
              <Button 
                href="#contact"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Подробнее
              </Button>
            </Card>
          ))}
        </PriceGrid>
      </PricesContent>
    </PricesContainer>
  );
}

export default Prices;