import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AdvantagesContainer = styled.section`
  padding: 4rem 5%;
  background-color: #f8f8f8;
`;

const AdvantagesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const AdvantageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled(motion.div)`
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }

  h3 {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 0.5rem;
  }

  p {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.6;
  }
`;

function Advantages() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const advantages = [
    {
      title: "Соблюдение сроков",
      description: "Все работы четко регламентированы по времени и всегда выполняются в срок, прописанный в договоре"
    },
    {
      title: "Качество изделий",
      description: "Наша компания имеет очень высокий рейтинг надежности по всем параметрам, поэтому у нас много постоянных клиентов"
    },
    {
      title: "Опытные специалисты",
      description: "Лицензированые специалисты широкого профиля с опытом работы"
    }
  ];

  return (
    <AdvantagesContainer id="advantages" ref={ref}>
      <AdvantagesContent>
        <Title>Наши преимущества</Title>
        <AdvantageGrid>
          {advantages.map((advantage, index) => (
            <Card
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <h3>{advantage.title}</h3>
              <p>{advantage.description}</p>
            </Card>
          ))}
        </AdvantageGrid>
      </AdvantagesContent>
    </AdvantagesContainer>
  );
}

export default Advantages;