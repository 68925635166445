import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import axios from 'axios';

const ContactContainer = styled.section`
  padding: 4rem 5%;
  background-color: var(--secondary-color);
  color: white;
`;

const ContactContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
`;

const ContactForm = styled.form`
  display: grid;
  gap: 1rem;
  margin-top: 2rem;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
`;

const Button = styled(motion.button)`
  padding: 0.8rem 1.5rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e6c200;
  }
`;

const SocialButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const SocialButton = styled(Button)`
  background-color: ${props => props.color};
  color: white;
`;

function Contact() {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('//xn----8sbpjvjngbgeh.xn--p1ai/send-email.php', formData);
          if (response.data.status === 'success') {
            alert('Сообщение успешно отправлено!');
            setFormData({ name: '', email: '', message: '' });
          } else {
            throw new Error(response.data.message);
          }
        } catch (error) {
          console.error('Ошибка при отправке:', error);
          alert('Произошла ошибка при отправке сообщения.');
        }
      };
  
    return (
      <ContactContainer id="contact">
        <ContactContent>
          <SectionTitle>Свяжитесь с нами</SectionTitle>
          <p>ООО "СтройМастер", Россия, г. Саратов, проезд Волочаевский дом 4, 26, 410003</p>
          <p>Телефон: 89991838096</p>
          <p>Email: stroimastersm@yandex.ru</p>
          <ContactForm onSubmit={handleSubmit}>
            <Input 
              type="text" 
              name="name" 
              placeholder="Ваше имя" 
              required 
              value={formData.name}
              onChange={handleChange}
            />
            <Input 
              type="email" 
              name="email" 
              placeholder="Ваш email" 
              required 
              value={formData.email}
              onChange={handleChange}
            />
            <TextArea 
              name="message" 
              placeholder="Ваше сообщение" 
              rows="5" 
              required 
              value={formData.message}
              onChange={handleChange}
            />
            <Button type="submit" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              Отправить
            </Button>
          </ContactForm>
          <SocialButtons>
            <SocialButton 
              as="a" 
              href={`https://wa.me/89991838096`} 
              target="_blank" 
              color="#25D366"
            >
              WhatsApp
            </SocialButton>
            <SocialButton 
              as="a" 
              href={`https://viber.click/89991838096`} 
              target="_blank" 
              color="#7360F2"
            >
              Viber
            </SocialButton>
            <SocialButton 
              as="a" 
              href={`mailto:stroimastersm@yandex.ru?subject=Запрос с сайта`} 
              color="#005FF9"
            >
              Mail.ru
            </SocialButton>
          </SocialButtons>
        </ContactContent>
      </ContactContainer>
    );
  }
  
  export default Contact;