import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const HeaderContainer = styled(motion.header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: rgba(26, 26, 26, 0.8);
  backdrop-filter: blur(5px);
  color: white;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 0.5rem 5%;
  }
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  color: #ffd700;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Nav = styled.nav`
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin-left: 2rem;

  a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;

    &:hover {
      color: #ffd700;
    }
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled(motion.div)`
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background-color: rgba(26, 26, 26, 0.95);
  padding: 1rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileNavItem = styled.a`
  display: block;
  color: white;
  text-decoration: none;
  padding: 0.5rem 0;
  text-align: center;
  transition: color 0.3s ease;

  &:hover {
    color: #ffd700;
  }
`;

function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
    const menuItems = [
      { id: 'home', name: 'Главная' },
      { id: 'mission', name: 'Миссия' },
      { id: 'values', name: 'Ценности' },
      { id: 'services', name: 'Услуги' },
      { id: 'prices', name: 'Цены' },
      { id: 'portfolio', name: 'Работы' },
      { id: 'contact', name: 'Контакты' }
    ];
  
    const scrollTo = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setMobileMenuOpen(false);
    };
  
    return (
      <HeaderContainer
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Logo>ООО "СтройМастер"</Logo>
        <Nav>
          <NavList>
            {menuItems.map((item) => (
              <NavItem key={item.id}>
                <a onClick={() => scrollTo(item.id)}>{item.name}</a>
              </NavItem>
            ))}
          </NavList>
        </Nav>
        <MobileMenuButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          {mobileMenuOpen ? '✕' : '☰'}
        </MobileMenuButton>
        <AnimatePresence>
          {mobileMenuOpen && (
            <MobileMenu
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {menuItems.map((item) => (
                <MobileNavItem key={item.id} onClick={() => scrollTo(item.id)}>
                  {item.name}
                </MobileNavItem>
              ))}
            </MobileMenu>
          )}
        </AnimatePresence>
      </HeaderContainer>
    );
  }
  
  export default Header;