import React from 'react';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import Header from './components/Header';
import Hero from './components/Hero';
import Mission from './components/Mission';
import Values from './components/Values';
import Services from './components/Services';
import Prices from './components/Prices';
import Portfolio from './components/Portfolio';
import Advantages from './components/Advantages';
import Contact from './components/Contact';
import Footer from './components/Footer';

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #ffd700;
    --secondary-color: #1a1a1a;
    --text-color: #333;
    --background-color: #f8f9fa;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: darken(var(--primary-color), 10%);
    }
  }
`;

const AnimatedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;

  @keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <AnimatedBackground />
      <Header />
      <Hero />
      <Mission />
      <Values />
      <Services />
      <Prices />
      <Portfolio />
      <Advantages />
      <Contact />
      <Footer />
    </>
  );
}

export default App;