import React from 'react';
import styled from 'styled-components';

const ValuesContainer = styled.section`
  padding: 4rem 5%;
  background-color: #ffffff;
`;

const ValuesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    text-align: center;
    color: #1a1a1a;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ValueItem = styled.div`
  background-color: #f8f8f8;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    color: #ffd700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

function Values() {
    const values = [
      {
        title: "Ответственность",
        description: "Мы объединены единой целью и несём ответственность за то, что мы создаем. Ответственное отношение сотрудников – залог успеха компании!"
      },
      {
        title: "Качество",
        description: "Профессионализм и компетентность нашей команды, ответственное и добросовестное отношение к обязанностям позволяют нам создавать качественный продукт, которым мы гордимся!"
      },
      {
        title: "Развитие",
        description: "Мы растем и развиваемся, внедряем новые технологии и делаем жизнь наших клиентов комфортной. Мы смело принимаем вызов и достигаем высоких результатов!"
      },
      {
        title: "Надежность",
        description: "Верность взятым на себя обязательствам. Честность и порядочность в отношении с партнерами и клиентами. Уверенность в завтрашнем дне!"
      }
    ];
  
    return (
      <ValuesContainer id="values">
        <ValuesContent>
          <h2>Ценности компании</h2>
          <p>Мы – команда профессионалов, объединенных одной целью. Каждый сотрудник важен и влияет на общий результат. Мы уважаем, доверяем и поддерживаем друг друга. Сила нашей команды в Единстве!</p>
          <ValueGrid>
            {values.map((value, index) => (
              <ValueItem key={index}>
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </ValueItem>
            ))}
          </ValueGrid>
        </ValuesContent>
      </ValuesContainer>
    );
  }
  
  export default Values;