import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #1a1a1a;
  color: white;
  padding: 2rem 5%;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Copyright = styled.p`
  margin: 0;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const SocialLinks = styled.div`
  a {
    color: white;
    margin-left: 1rem;
    font-size: 1.2rem;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #ffd700;
    }

    @media (max-width: 768px) {
      margin: 0 0.5rem;
    }
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <Copyright>&copy; 2022 ООО "СтройМастер". Все права защищены.</Copyright>
        <SocialLinks>
          <a href="#" target="_blank" rel="noopener noreferrer">WhatsApp</a>
          <a href="#" target="_blank" rel="noopener noreferrer">Viber</a>
          <a href="#" target="_blank" rel="noopener noreferrer">Mail.ru</a>
        </SocialLinks>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;