import React from 'react';
import styled from 'styled-components';

const ServicesContainer = styled.section`
  padding: 4rem 5%;
  background-color: #f8f8f8;
`;

const ServicesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    text-align: center;
    color: #1a1a1a;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const ServicesList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceItem = styled.li`
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

function Services() {
    const services = [
      "Строительство жилых и нежилых зданий",
      "Монтаж отопительных систем и систем кондиционирования воздуха",
      "Производство малярных и стекольных работ",
      "Производство прочих отделочных и завершающих работ",
      "Производство электромонтажных работ",
      "Разработка строительных проектов",
      "Производство кровельных работ"
    ];
  
    return (
      <ServicesContainer id="services">
        <ServicesContent>
          <h2>Наши услуги</h2>
          <ServicesList>
            {services.map((service, index) => (
              <ServiceItem key={index}>{service}</ServiceItem>
            ))}
          </ServicesList>
        </ServicesContent>
      </ServicesContainer>
    );
  }
  
  export default Services;